import React from "react";
import CommonLayout from "../../layout/CommonLayout";

import * as styles from './style.module.css';

import indexcardImg from '../../assets/indexcard.png';
import recordsImg from '../../assets/records.png';

import recCropImg from '../../assets/recCrop.jpg';
import recListImg from '../../assets/recList.jpg';

import expandImg from '../../assets/icons/expand.png';


const exIcon = <img src={expandImg} alt="Opens to new tab/window" style={{width: 20, height: 20, opacity: 0.4}}/>


const ItemBlock = (props: any) => {
    const img = props.image
        ? <img src={props.image} alt={props.alt ?? "unspecified"} />
        : null
    ;
   
    const caption = props.caption 
        ? <div className={styles['caption']}>
            {props.caption}
        </div>
        :null
    ;

    const prevImg = props.previewImage 
        ? <img src={props.previewImage} alt={props.previewAlt ?? "preview"} />
        : null
    ;
    var preview = null;
    var previewTip = null;
    if (props.preview && (prevImg || img)) {
        
        const previewStyle={ 
            transform: "scale(" + (props.zoom > 0 ? "" + props.zoom : "1.0") + ")" 
                       + " " + (!props.previewPos ? "translate(-10%, -10%)" : "translate(" + props.previewPos +")")
        };

        preview = <div className={styles['preview']} style={previewStyle} >
            {prevImg ?? img}
        </div>
        ;
        console.log("Zoom: " + props.zoom + " Transalate: (" + props.previewPos + ")");
        console.log(previewStyle);

        previewTip = <div className={styles['prevTip']}>
            🔎 Hold to Preview [+]
        </div>;
    }

    

    return(

        <div className={styles["block"]}
            style={{ width: props.width ? props.width : null,
                height: props.height ? props.height : null,
                margin: props.margin ? props.margin : null,
            }}
        >
            {img}
            {caption}

            {previewTip}
            
            {preview}
        </div>
    );

}


const Article = (props:any) => {
    return (
        <a href={props.link} target="_blank">
            <div className={styles["artItem"]}>
                    
                <span className={styles["artTitle"]}>{props.title} {exIcon}</span>
                <div><i>{props.site}</i></div>
                
                {/* <text className={styles["navItem"]}>
                    {props.link}
                </text> */}
            </div>
        </a>
    );
}

const archiveLink= "https://collections.arolsen-archives.org/en/search/person/1721624?s=Lionel%20Romney&t=222854&p=1";



const StoryPage = () => {

    return (

        <CommonLayout>

            <Article title={"View the record of Lionel Romney in the Arolsen Archives"}
                site={"Arolsen Archives - Online Historical Concentration Camp Database"}
                link={archiveLink}
            />

            <span className={styles["records"]}>

                <ItemBlock image={indexcardImg} caption=
                        "Stamped index card"
                        width={400} preview={true} zoom={1.5}
                        
                    />
                
                <ItemBlock image={recordsImg} caption=
                        "Official records from Mauthausen concentration camp in Austria"
                        width={900} preview={true} zoom={1.5}
                    />

            </span>

            <ItemBlock image={recCropImg} caption=
                "Concentration camp entry amongst list of prisoners."
                width={700} margin={"200px auto 100px auto"}
                preview={true} previewImage={recListImg} zoom={0.5}
                previewPos={"-60%,-90%"}
            />
            

           
            

        </CommonLayout>
    
    );

}

export default StoryPage;
