// extracted by mini-css-extract-plugin
export var artItem = "style-module--artItem--fcd96";
export var artTitle = "style-module--artTitle--ad878";
export var block = "style-module--block--1e731";
export var caption = "style-module--caption--82787";
export var container = "style-module--container--5e19a";
export var content = "style-module--content--742b9";
export var intSeg = "style-module--intSeg--63f77";
export var prevTip = "style-module--prevTip--46732";
export var preview = "style-module--preview--7dc72";
export var records = "style-module--records--0e511";
export var segText = "style-module--segText--d6960";